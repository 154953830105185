
//
const PeopleManager = () => import(/* webpackChunkName: "CcementMeetingPeopleManager" */'@/views/PeopleManager')
const PeopleManagerDetail = () => import(/* webpackChunkName: "CcementMeetingPeopleManagerDetail" */'@/views/PeopleManager/Detail')
const PeopleManagerNew = () => import(/* webpackChunkName: "CcementMeetingPeopleManagerNew" */'@/views/PeopleManager/New')
const PeopleManagerEdit = () => import(/* webpackChunkName: "CcementMeetingPeopleManagerEdit" */'@/views/PeopleManager/Edit')

const PeopleOldManager = () => import(/* webpackChunkName: "CcementMeetingPeopleOldManager" */'@/views/PeopleOldManager')
const PeopleOldManagerNew = () => import(/* webpackChunkName: "CcementMeetingPeopleOldManagerNew" */'@/views/PeopleOldManager/New')
const PeopleOldManagerEdit = () => import(/* webpackChunkName: "CcementMeetingPeopleOldManagerEdit" */'@/views/PeopleOldManager/Edit')

const OrderManager = () => import(/* webpackChunkName: "CcementMeetingOrderManager" */'@/views/OrderManager')
const OrderManagerDetail = () => import(/* webpackChunkName: "CcementMeetingOrderManagerDetail" */'@/views/OrderManager/Detail')

const CompanyManager = () => import(/* webpackChunkName: "CcementMeetingCompanyManager" */'@/views/CompanyManager')
const CompanyManagerNew = () => import(/* webpackChunkName: "CcementMeetingCompanyManagerNew" */'@/views/CompanyManager/New')
const CompanyManagerEdit = () => import(/* webpackChunkName: "CcementMeetingCompanyManagerEdit" */'@/views/CompanyManager/Edit')
const CompanyManagerRoom = () => import(/* webpackChunkName: "CcementMeetingCompanyManagerRoom" */'@/views/CompanyManager/Room')

const CompanyManagerPeople = () => import(/* webpackChunkName: "CcementMeetingCompanyManagerPeople" */'@/views/CompanyManager/People')

const MeetingManager = () => import(/* webpackChunkName: "CcementMeetingMeetingManager" */'@/views/MeetingManager')
const MeetingManagerNew = () => import(/* webpackChunkName: "CcementMeetingMeetingManagerNew" */'@/views/MeetingManager/New')
const MeetingManagerEdit = () => import(/* webpackChunkName: "CcementMeetingMeetingManagerEdit" */'@/views/MeetingManager/Edit')
const MeetingManagerAttachment = () => import(/* webpackChunkName: "CcementMeetingMeetingManagerAttachment" */'@/views/MeetingManager/Attachment')
const MeetingManagerSponsor = () => import(/* webpackChunkName: "CcementMeetingMeetingManagerSponsor" */'@/views/MeetingManager/Sponsor')

const HotelManager = () => import(/* webpackChunkName: "CcementMeetingHotelManager" */'@/views/HotelManager')
const HotelManagerNew = () => import(/* webpackChunkName: "CcementMeetingHotelManagerNew" */'@/views/HotelManager/New')
const HotelManagerEdit = () => import(/* webpackChunkName: "CcementMeetingHotelManagerEdit" */'@/views/HotelManager/Edit')
const HotelManagerDetail = () => import(/* webpackChunkName: "CcementMeetingHotelManagerDetail" */'@/views/HotelManager/Detail')
const RoomDetail = () => import(/* webpackChunkName: "CcementMeetingRoomDetail" */'@/views/RoomDetail')

const RegisterList = () => import(/* webpackChunkName: "CcementMeetingRegisterList" */'@/views/RegisterList')
const RegisterListNew = () => import(/* webpackChunkName: "CcementMeetingRegisterListNew" */'@/views/RegisterList/New')
const RegisterListEdit = () => import(/* webpackChunkName: "CcementMeetingRegisterListEdit" */'@/views/RegisterList/Edit')
const RegisterPeople = () => import(/* webpackChunkName: "CcementMeetingRegisterPeople" */'@/views/RegisterPeople')

const VoteList = () => import(/* webpackChunkName: "CcementMeetingVoteList" */'@/views/VoteList')
const VoteListNew = () => import(/* webpackChunkName: "CcementMeetingVoteListNew" */'@/views/VoteList/New')
const VoteListEdit = () => import(/* webpackChunkName: "CcementMeetingVoteListEdit" */'@/views/VoteList/Edit')

const VoteType = () => import(/* webpackChunkName: "CcementMeetingVoteType" */'@/views/VoteType')
const VoteTypeNew = () => import(/* webpackChunkName: "CcementMeetingVoteTypeNew" */'@/views/VoteType/New')
const VoteTypeEdit = () => import(/* webpackChunkName: "CcementMeetingVoteTypeEdit" */'@/views/VoteType/Edit')

const MeetingPrediction = () => import(/* webpackChunkName: "CcementMeetingMeetingPrediction" */'@/views/MeetingPrediction')
const MeetingPredictionNew = () => import(/* webpackChunkName: "CcementMeetingMeetingPredictionNew" */'@/views/MeetingPrediction/New')
const MeetingPredictionEdit = () => import(/* webpackChunkName: "CcementMeetingMeetingPredictionEdit" */'@/views/MeetingPrediction/Edit')

const MeetingQRCode = () => import(/* webpackChunkName: "CcementMeetingMeetingQRCode" */'@/views/MeetingQRCode')

export default {
  PeopleManager: {
    component: PeopleManager,
  },
  'PeopleManager-Detail': {
    path: 'Detail/:id',
    component: PeopleManagerDetail,
  },
  'PeopleManager-NewOrUpdate': {
    path: [ 'New', 'Edit/:id' ],
    component: [ PeopleManagerNew, PeopleManagerEdit ],
  },

  OrderManager: {
    component: OrderManager,
  },

  'OrderManager-Detail': {
    path: 'Detail/:id',
    component: OrderManagerDetail,
  },

  CompanyManager: {
    component: CompanyManager,
  },

  'CompanyManager-NewOrUpdate': {
    path: [ 'New', 'Edit/:id' ],
    component: [ CompanyManagerNew, CompanyManagerEdit ],
  },

  'CompanyManager-Room': {
    path: 'Room/:id',
    component: CompanyManagerRoom,
  },

  'CompanyManager-People': {
    path: 'People/:id',
    component: CompanyManagerPeople,
  },

  MeetingManager: {
    component: MeetingManager,
  },

  'MeetingManager-NewOrUpdate': {
    path: [ 'New', 'Edit/:id' ],
    component: [ MeetingManagerNew, MeetingManagerEdit ],
  },

  'MeetingManager-Attachment': {
    path: 'Attachment/:id',
    component: MeetingManagerAttachment,
  },

  'MeetingManager-Sponsor': {
    path: 'Sponsor/:id',
    component: MeetingManagerSponsor,
  },

  HotelManager: {
    component: HotelManager,
  },

  'HotelManager-NewOrUpdate': {
    path: [ 'New', 'Edit/:id' ],
    component: [ HotelManagerNew, HotelManagerEdit ],
  },

  'HotelManager-Detail': {
    path: 'Detail/:id',
    component: HotelManagerDetail,
  },

  RoomDetail: {
    component: RoomDetail,
  },

  RegisterList: {
    component: RegisterList,
  },

  'RegisterList-NewOrUpdate': {
    path: [ 'New', 'Edit/:id' ],
    component: [ RegisterListNew, RegisterListEdit ],
  },

  RegisterPeople: {
    component: RegisterPeople,
  },

  VoteList: {
    component: VoteList,
  },

  'VoteList-NewOrUpdate': {
    path: [ 'New', 'Edit/:id' ],
    component: [ VoteListNew, VoteListEdit ],
  },

  VoteType: {
    component: VoteType,
  },

  'VoteType-NewOrUpdate': {
    path: [ 'New', 'Edit/:id' ],
    component: [ VoteTypeNew, VoteTypeEdit ],
  },
  
  MeetingPrediction: {
    component: MeetingPrediction,
  },

  'MeetingPrediction-NewOrUpdate': {
    path: [ 'New', 'Edit/:id' ],
    component: [ MeetingPredictionNew, MeetingPredictionEdit ],
  },

  MeetingQRCode: {
    component: MeetingQRCode,
  },
  PeopleOldManager: {
    component: PeopleOldManager,
  },
  'PeopleOldManager-NewOrUpdate': {
    path: [ 'New', 'Edit/:id' ],
    component: [ PeopleOldManagerNew, PeopleOldManagerEdit ],
  },
  
}
