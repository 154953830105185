(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Vuex"), require("ELEMENT"), require("VueRouter"), require("Vue"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define(["Vuex", "ELEMENT", "VueRouter", "Vue", "axios"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("Vuex"), require("ELEMENT"), require("VueRouter"), require("Vue"), require("axios")) : factory(root["Vuex"], root["ELEMENT"], root["VueRouter"], root["Vue"], root["axios"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__5880__, __WEBPACK_EXTERNAL_MODULE__5f72__, __WEBPACK_EXTERNAL_MODULE__6389__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_cebe__) {
return 