const state = {
  token: window.token,
  userInfo: '',
}

const mutations = {
  _setUserInfo (state, data) {
    state.userInfo = data
  },
}

const actions = {
  async getUserInfo ({ commit }, data) {
    commit('_setUserInfo', data)
  },
}

export default {
  state,
  mutations,
  actions,
}
