import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'
import { setPublicPath } from 'systemjs-webpack-interop'
import App from './App.vue'
import router from './router'
import store from './store'
import routerData from './router/data'

setPublicPath(process.env.VUE_APP_MODULE, 2)

Vue.config.productionTip = false

if (Vue.prototype.$getUserInfo && Vue.prototype.$getUserInfo()) {
  store.dispatch(bus.event.GET_USER_INFO, Vue.prototype.$getUserInfo())
} else {
  bus.$once('getUserInfo', (res) => {
    store.dispatch(bus.event.GET_USER_INFO, res)
  })
}

if (Vue.prototype.$getKey && Vue.prototype.$getKey()) {
  addRoutes()
} else {
  bus.$once(bus.event.GET_ROUTING_MAP, addRoutes)
}

/** 路由拦截 */
router.beforeEach(async (to, from, next) => {
  if (to.name === null && from.name === null) return
  if (to.path !== from.path && location.pathname.split('/')[1] === process.env.VUE_APP_MODULE) {
    Vue.prototype.$NProgress.start()
  }
  next()
})
router.afterEach(() => {
  if (location.pathname.split('/')[1] === process.env.VUE_APP_MODULE) {
    Vue.prototype.$NProgress.done()
  }
})
/** 路由拦截end */

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    el: `#${spas[process.env.VUE_APP_MODULE].id}`,
    router,
    store,
    render: (h) => h(App),
  },
})
console.info(`%c${process.env.VUE_APP_MODULE + ':' + process.env.VUE_APP_VERSION}`, 'background:#ccc; padding:5px; color:blue; border-radius: 5px;')
export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount

function addRoutes () {
  const routingMap = Vue.prototype.$getKey()
  const routes = []
  for (const key in routerData) {
    const routing = routingMap[key]
    if (!routing) continue
    const item = routerData[key]
    if (item.path instanceof Array) {
      item.path.forEach((one, index) => {
        routes.push({
          path: '/' + routing.url + (one ? '-' + one : ''),
          component: item.component[index],
        })
      })
    } else {
      routes.push({
        path: '/' + routing.url + (item.path ? '-' + item.path : ''),
        component: item.component,
      })
    }
  }
  router.addRoutes(routes)
}
